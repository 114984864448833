import * as React from 'react';
import * as styles from './page.module.css';

function Page({ id = undefined, refElement, children }) {
  return (
    <div id={id} ref={refElement} className={styles.page}>
      {children}
    </div>
  )
}

export default Page;
