import * as React from 'react';
import * as styles from './contact.module.css';
import Page from './page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { PageTitle } from '../../components/page-title';
import { COLORS } from '../../constants';
import { Spacing } from '../../components/spacing';

function Contact({ refElement }) {
  return (
    <Page id="contact" refElement={refElement}>
      <Spacing size="large">
        <PageTitle color={COLORS.contact}>Contact</PageTitle>
      </Spacing>
      <p className={styles.contactText}>
        If you'd like to get in touch, please reach out to me by LinkedIn or
        email!
      </p>

      <div className={styles.iconsContainer}>
        <a className={styles.iconLink} href="mailto:annieesanford@gmail.com">
          <span className={`material-icons ${styles.icon}`}>email</span>
        </a>
        <a
          className={styles.iconLink}
          href="https://www.linkedin.com/in/annie-sanford/"
        >
          <FontAwesomeIcon className={styles.icon} icon={faLinkedin} />
        </a>
      </div>
    </Page>
  );
}

export default Contact;
